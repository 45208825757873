<template>
  <div class="pay">
    <div :style="{ display: display }">
      <van-cell-group class="header" :style="{ 'background-color': color }">
        <div class="title">
          <div class="img"><img :src="src" alt="" /></div>
          <div class="name">{{ title }}</div>
        </div>
      </van-cell-group>
    </div>
    <div  :style="{ display: display }" style="height: 35px">
      <div class="content">
        <div class="money_con">
          <div>消费金额(元)</div>
          <div class="money">￥{{ numbertext }}</div>
        </div>
        <div class="show_money" v-if="num > 0 && numberval > 0">
          <div>优惠：<span style="color:orange;">-{{ (numbertext - finalMoney).toFixed(2) }}</span>元</div>
          <div>实际支付：<span style="color:orange;">{{ finalMoney }}</span>元</div>
        </div>
      </div>
    </div>

    <div  class="gg">
      <img :src="imgSrc" alt="" />
    </div>

    <div class="tt">未莱科技 400-136-1360</div>

    <div  :style="{ display: display }">
        <van-number-keyboard :class="classStr"
                             :show="show"
                             theme="custom"
                             extra-key="."
                             close-button-text="去支付"
                             @input="onInput"
                              @delete="onDelete"
                             @close="payment" />
    </div>
    <van-popup class="popup" v-model="popup"><img class="popup" src="@/assets/b.gif" alt="" /></van-popup>
    <pay class="page" ref="pay" v-bind:pay="message" ></pay>
  </div>
</template>

<script>
import util from "../../util/util";
import pay from "../../components/pay.vue";
import { WX_APP_ID, ALI_APP_ID } from '../../util/constant';


import {
  Tag,
  Col,
  Icon,
  Cell,
  CellGroup,
  Swipe,
  Toast,
  SwipeItem,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  NumberKeyboard,
  Popup,
} from 'vant';
export default {
  components: {
    [Tag.name]: Tag,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [GoodsAction.name]: GoodsAction,
    [GoodsActionIcon.name]: GoodsActionIcon,
    [GoodsActionButton.name]: GoodsActionButton,
    [NumberKeyboard.name]: NumberKeyboard,
    [Popup.name]: Popup,
    "pay": pay,
  },
  computed: {
    finalMoney() {
      if (this.num <= 0) {
        return this.numbertext;
      }
      let a = this.num / 100;
      a = a.toFixed(2);
      let b = this.numbertext * a;
      return b.toFixed(2);
    }
  },

  data() {
    return {
      queryCode:"",//route上的code
      tuiguang:"",//接口返回的推广链接
      imgSrc:"",//接口返回的图片
      show: true,
      numberval: "",
      numbertext: "0.00",
      num: 0,
      color: "#469DFA",
      title: "",
      src: require("@/assets/shop_logo_b.png"),
      classStr: "blue",
      popup: true,
      message: '',
      paySource: '0',
      display: 'block',
      image: require("@/assets/wlcm.png"),
      img: 'none',
      state: 0,
      form: {
        openId: undefined,
        id: undefined
      }
    };
  },

  mounted() {
    this.init();
  },
  methods: {
    // 初始化数据
    init() {
      // 当前路由参数
      let query = this.$route.query;
      let id = util.getData('id');
      let  codes = util.getData('codes');
      if (!id){
        id = query.id
        util.setData('id',id);
      }
      if (!codes){
        codes = query.codes;
        util.setData('codes',codes);
      }

      // this.queryCode = query.codes;//从路径上截取的code
      if (id && codes) {
        this.form.id = id;
        this.queryCode = codes;
        // util.setData('id', query.id);
      } else {
        Toast('请重新扫描二维码')
        return;
      }
      // 判断浏览器
      let isAlipayOrWechat = util.isAlipayOrWechat();
      if (isAlipayOrWechat == "other") {
        Toast("请用微信或支付宝浏览器打开!")
        return;
      }
      // 获取当前url
      let url = window.location.href;
      // 微信
      if (isAlipayOrWechat == "wechat") {
        this.paySource = '0';
        // 判断当前url是否包含code参数
        if (query.code) {
          // 获取openid
          this.getOpenId(query.code, openId => {
            this.query(this.form.id, openId)
          });
        } else {
          // 如果不包含code参数，则跳转到微信授权页面
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${WX_APP_ID}&redirect_uri=${encodeURIComponent(url)}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
        }
      }
      // 支付宝
      if (isAlipayOrWechat == "alipay") {
        this.paySource = '1';
        // 判断当前url是否包含auth_code参数
        if (query.auth_code) {
          // 获取openid
          this.getUserId(query.auth_code, openId => {
            this.query(this.form.id, openId)
          });
        } else {
          // 如果不包含code参数，则跳转到微信授权页面
          window.location.href = `https://openauth.alipaydev.com/oauth2/publicAppAuthorize.htm?app_id=${ALI_APP_ID}&scope=auth_base&redirect_uri=${encodeURIComponent(url)}`
        }
      }
    },
    // 支付宝获取userid
    async getUserId(code, callback) {
      let openId = util.getData('openId')
      if (!openId) {
        const obj = await this.$get('/jsapi/get_userid', {
          code: code
        });
        if (obj.code != 200) {
          return
        }
        openId = obj.data;
      }
      util.setData('openId', openId);
      this.form.openId = openId;
      callback(openId)
    },
    async getOpenId(code, callback) {
      let openId = util.getData('openId')
      if (!openId) {
        const obj = await this.$get('/jsapi/get_openid', { code: code });
        if (obj.code != 200) {
          return
        }
        openId = obj.data;
      }
      util.setData('openId', openId);
      this.form.openId = openId;
      callback(openId)
    },

    query(id = undefined, openId = undefined) {
      this.$get("/jsapi/get_info", {//获取首页数据接口
        source: this.paySource,
        id: id,
        openId: openId,
      }).then(res => {
        if (res.code == 200) {
          this.popup = false;
          this.tuiguang = res.data.tuiguang;
          this.imgSrc = res.data.img;
          this.title = res.data.title;
          this.state = res.data.state;
          this.num = 0;
        } else {
          Toast(res.message);
        }
      }).catch(err =>{
        Toast(err)
      })
    },
    payment() {
      let that = this;
      let code = util.getData('id');
      if (!code) {
        Toast("请重新扫描二维码");
        return
      }
      if (this.numberval <= 0) {
        Toast("请输入支付金额")
        return
      }
      this.$json("/jsapi/payment_h5", {
        zhandianId: code,
        money: this.numberval,
        paySource: this.paySource,
        openId: this.form.openId,
        code:this.queryCode,
      }).then(res => {
        if (res.code == 200) {
          that.display = 'none';
          that.img = 'none';
          util.setData("action",0)
          that.$refs.pay.getMsg(res.data.message);
        }
      })
    },
    onInput(value) {
      this.$copyText(this.tuiguang).then(function (e) {
        window.console.log(e)
      }, function (e) {
        window.console.log(e)
      });
      let number = this.numberval.toString() + value.toString();
      if (number.indexOf('.') == 0) { //第一位就是 .
        number = "0" + number;
      }
      number = number.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      number = number.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      if (number.indexOf(".") != -1) {
        number = number.substring(0, number.indexOf(".") + 3);
      }
      if (number.length > 9) {
        return;
      }
      if (number.indexOf('00') == 0) {
        return
      }
      this.numberval = number;
      this.numbertext = number;
    },
    onDelete() {
      let index = this.numberval == '' ? 0 : this.numbertext.length;
      if (index > 1) {
        let numStr = this.numbertext.substring(0, index - 1);
        this.numberval = numStr;
        this.numbertext = numStr;
      } else {
        this.numberval = "";
        this.numbertext = "0.00";
      }
    },
  },

};


</script>

<style lang="less" >
.container {
  font-size: 1.5rem;
}
.pay {
  width: 100%;
  margin: 0;
  padding: 0;
  .gg {
    width: 90%;
    border: #e5e5e5 1px dashed;
    border-radius: 1rem;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  .tt {
    width: 100%;
    color: #aaa;
    text-align: center;
    margin-top:10px;
    font-size: 0.75rem;
  }
  .header {
    width: 100%;
    height: 10rem;
    background-color: #ff8e04;
    .title {
      width: 100%;
      height: 2rem;
      line-height: 2rem;
      padding: 2.5rem 0;
      color: #fff;
      text-align: center;
      display: flex;
      justify-content: center;
      .img {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background-color: #fff;
        img {
          width: 1.5rem;
          height: 1.5rem;
          margin-top: 0.25rem;
        }
      }
      .name {
        margin-left: 0.5rem;
        font-size: 1.5rem;
        font-weight: bold;
        max-width: 80%;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .content {
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 0.5rem;
    width: 95%;
    position: relative;
    padding: 10px;
    top: -2rem;
    margin: 0 auto;
    .money_con {
      display: flex;
      justify-content: space-between;
      div {
        height: 2.5rem;
        line-height: 2.5rem;
        color: #666;
        font-size: 1rem;
      }
      .money {
        font-size: 1.8rem;
        color: #000;
        font-weight: bold;
      }
    }
    .show_money {
      text-align: right;
      font-size: 12px;
      color: #999;
    }
  }
  .blue .van-key--blue {
    background-color: #469dfa;
    height: 9.8rem;
    position: relative;
    .van-key--delete {
      font-size: 16px;
      height: 2.8rem;
    }
  }
  .yellow .van-key--blue {
    background-color: #ff8e04;
    writing-mode: vertical-rl;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .van-hairline--top-bottom::after {
    border-width: 0;
  }
  .popup {
    width: 100%;
    height: 100vh;
    z-index: 999;
  }
}
</style>

